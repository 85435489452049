<template>
    <div class="card card-f-h w-full">
        <h1>FortnoxIntegration</h1>
        <el-button @click="initiateIntegration" :disabled="currentIntegration">Activate integration</el-button>
    </div>
</template>
<script>
import queryString from 'query-string';

export default {
    data() {
        return {
            currentIntegration: 0,
        };
    },

    created() {
        const query = queryString.parse(window.location.search);
        this.checkCurrentIntegration();

        if (query.code) {
            this.activateIntegration(query.code);
        }
    },

    methods: {
        async checkCurrentIntegration() {
            this.currentIntegration = 1;
        },

        initiateIntegration() {
            window.location.href = `https://apps.fortnox.se/oauth-v1/auth?client_id=${process.env.VUE_APP_FORTNOX_CLIENT_ID}&redirect_uri=https://app.workflows.se/settings/economy/fortnox_integration&scope=companyinformation%20article%20invoice%20customer&access_type=offline&state=workflows&response_type=code`;
        },

        async activateIntegration(code) {
            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/settings/activate_fortnox`, {
                code,
            });
            this.checkCurrentIntegration();
            this.$router.replace({}).catch(() => {});
        },
    },
};
</script>
